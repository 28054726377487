<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store'

const route = useRoute()
const host = process.server
  ? useRequestHeaders().host
  : window.location.host

useHead({
  link: [
    // We use route.path since we don't use query parameters
    { rel: 'canonical', href: `https://${host}${route.path}` }
  ]
})

const signOut = () => {
  const authStore = useAuthStore()
  authStore.signOut()
  location.reload()
}
</script>

<template>
  <div>
    <!-- <header class="header">
      <nav
        class="inner"
        role="navigation"
      >
        <button @click="signOut()">
          Đăng xuất
        </button>
      </nav>
    </header> -->
    <slot role="main" />
  </div>
</template>
